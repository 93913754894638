import { variables } from "../../styles/variables";
import { EmotionStyles } from "../../interfaces/emotion";
import { colors } from "../../styles/colors";
import { breakpointMixins } from "../../styles/breakpoints";

const { spacing } = variables;

export const formStyles: EmotionStyles = {
  form: {
    marginBottom: spacing.large,
    paddingBottom: spacing.large
  },
  label: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1
  },
  input: {
    flexGrow: 1,
    padding: `${spacing.medium} ${spacing.small}`,
    marginBottom: spacing.small,
    width: "100%",

    [breakpointMixins.md]: {
      marginBottom: 0,
      width: "auto",
      marginRight: spacing.small
    }
  },
  button: {
    backgroundColor: colors.primary,
    border: "none"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 450
  },
  feedBack: {
    marginTop: spacing.medium,
    color: colors.grey,

    "&.form-error": {
      color: colors.pinkDark
    },
    "&.form-success": {
      color: colors.body
    }
  }
};
