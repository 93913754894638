import React, { useState } from "react";
import { EmailFormFields } from "react-mailchimp-subscribe";
import { formStyles as styles } from "./styles";
import { styles as buttonStyles } from "../Button/styles";

interface CustomFormProps {
  status: "error" | "success" | "sending" | null;
  message: string | Error | null;
  onValidated: (data: EmailFormFields) => void;
}

const CustomForm: React.FC<CustomFormProps> = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [honeyPot, setHoneyPot] = useState("");
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (honeyPot) return;
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email
      });
  }
  return (
    <form className="mc__form" onSubmit={(e) => handleSubmit(e)} css={styles.form}>
      <h3 className="mc__title">Join the WMDJ email list for future updates.</h3>

      <div className="mc__field-container" css={styles.fieldContainer}>
        <label htmlFor="email">Email</label>
        <div css={styles.inputContainer}>
          <input
            id="email"
            placeholder="Your email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required={true}
            css={styles.input}
          />
          <input name="name" placeholder="Fred" type="hidden" value={honeyPot} onChange={e => setHoneyPot(e.target.value)}
          />
          <input type="submit" disabled={!email} css={[buttonStyles.button, styles.button]} />
        </div>
        <div css={styles.feedBack}>
          {status === "sending" && (
            <div className="mc__alert mc__alert--sending">
              Sending...
            </div>
          )}
          {status === "error" && typeof message === "string" && (
            <div
              className="mc__alert mc__alert--error form-error"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && typeof message === "string" && (
            <div
              className="mc__alert mc__alert--success form-success"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default CustomForm;
