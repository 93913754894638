import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from './CustomForm';


const MailchimpFormContainer = () => {
  const postUrl = `${process.env.GATSBY_MAILCHIMP_FORM_URL}?u=${process.env.GATSBY_MAILCHIMP_U}&id=${process.env.GATSBY_MAILCHIMP_ID}`;
  return (
    <div className="mc__form-container">
      <MailchimpSubscribe url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )} />
    </div>
  );
};

export default MailchimpFormContainer;