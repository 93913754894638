import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import { toPlainText } from "../helpers/content";
import Page from "../components/Page";
import { PageInterface } from "../interfaces/page";
import { SiteInterface } from "../pages";
import ContactPage from "../components/ContactPage";

interface PageTemplateProps {
  data: {
    page: PageInterface;
    site: Omit<SiteInterface, "description">;
  };
  errors: Array<GraphQLError>;
}

export const query = graphql`
  query CmsPageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      buyMeACoffee
    }
    site: sanitySiteSettings {
      title
      keywords
    }
  }
`;

const PageTemplate: React.FC<PageTemplateProps> = props => {
  const { data, errors } = props;
  const page = data && data.page;
  const site = data && data.site;
  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {page && site && (
        <SEO
          title={`${page.title || "Untitled"} | ${site.title}`}
          description={toPlainText(page._rawExcerpt)}
          keywords={site.keywords}
        />
      )}

      {errors && <GraphQLErrorList errors={errors} />}
      {page && page.slug?.current === "contact" ? (
        <ContactPage {...page} />
      ) : (<Page {...page} />)}
    </>
  );
};

export default PageTemplate;
